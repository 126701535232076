import React from 'react';

//Styling
import './afspraak.css';

const Afspraak = (props) => {

    const afspraak_met = props.afspraak_met;
    const logo = props.logo;

    return(
        <div className="afspraak">
            <div className="afspraak-content">
                {
                    logo ?
                    <img src={logo} alt="Logo" />
                    :
                    null
                }
                <h3>{afspraak_met}</h3>
            </div>
        </div>
    );
}

export default Afspraak;