import { useState, useEffect, useRef } from 'react';
import './App.css';

import Lottie from "lottie-react";
import homeAnimation from "./assets/animations/home.json";
import { TailSpin } from  'react-loader-spinner';
import { BsWifi, BsWifiOff } from "react-icons/bs";

//API
import api from './settings/AxiosSetup';

//Images
import logo from './assets/img/logo.svg';
import artwork from './assets/img/logo-detail.svg';

import React from 'react';
import Moment from 'react-moment';
import 'moment/locale/nl';
import moment from 'moment'
import 'moment-timezone';
import axios from 'axios';
import xml2js from 'xml2js';
import { io } from 'socket.io-client'

import { ArrowDown } from 'react-bootstrap-icons';

import Afspraak from './components/afspraak';

function App() {

  const lottieAnimation = useRef();

  moment.locale('nl');

  //Get afspraken
  const getAfspraken = async () => {
    try {
      const response = await api.get('afspraken');
      if (response.data.data) {
        const result = response.data.data;
        setWelkomTekst(result.welkomtekst);
        setInfoTekst(result.infotekst);
        setIcon(result.icoon);
        setAfspraken(result.afspraken);
        setIsLoading(false);
        setNetwork(true);
      }
    } catch (error) {
      if (error.code === 'ERR_NETWORK') {
        setNetwork(false);
      }
    }
  }

  //Animation function
  const animation = () => {
    setTimeout(function() {
      lottieAnimation.current.play();
    }, 5000)
    setTimeout(function() {
      lottieAnimation.current.pause();
    }, 17000)
  }

  const [isLoading, setIsLoading] = useState(false);
  const [network, setNetwork] = useState(true);
  const [day, setDay] = useState(moment().format('dddd D MMMM'));
  const [time, setTime] = useState(moment().format('HH:mm'));
  const [welkomTekst, setWelkomTekst] = useState('Welkom bij van Straalen de Vries');
  const [infoTekst, setInfoTekst] = useState('Graag aanmelden via onderstaande tablet');
  const [naam, setNaam] = useState('');
  const [type, setType] = useState('welcome');
  const [icon, setIcon] = useState('https://stagingregistratie.vsdv.nl/wp-content/uploads/2023/02/icon.svg');
  const [afspraken, setAfspraken] = useState([]);
  const [showNotification, setShowNotification] = useState(false);

  const delay = ms => new Promise(res => setTimeout(res, ms));

  useEffect(() => {
    setInterval(function () {
      setDay(moment().format('dddd D MMMM'));
      setTime(moment().format('HH:mm'));
    }, 1000);
    setInterval(function () {
      getAfspraken();
    }, 30000);
    getAfspraken();
    setInterval(function () {
      animation();
    }, 23000);
    animation();
    //getCalendarEvents(username, password, '2023-03-23T:12:00Z', '2023-03-23T:17:00Z');

    const socket = io('https://welkom-server.vsdv.nl')
    socket.on('connect', ()=> console.log('connected maat'))
    socket.on("sendData", (data) => {
        setNaam(data.body.name);
        setType(data.body.type);
        if (data.header.includes('Android')) {
          setShowNotification(true);
          // Delay for 5 seconds
          setTimeout(() => {
            setShowNotification(false);
          }, 15000);
        }

    });

  }, []);

return (
    <div className="root">
      {
        isLoading ?
        <div className="loader">
        <TailSpin
          height="180"
          width="180"
          color="#001C43"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        </div>
        :
        <div className="root-content">
          <div className="header">
            
            <p className="day">{day}</p>
            <div className="animation">
              <Lottie lottieRef={lottieAnimation} autoplay={false} animationData={homeAnimation} />
            </div>
            <p className="time">{time}</p>
            <div className="network">
                {
                  network ?
                  <BsWifi style={{color: '#00FF00', fontSize: 50}} />
                  :
                  <BsWifiOff style={{color: '#FF0000', fontSize: 50}}/>
                }
          </div>
          </div>

          <div className="content">
            <h1>{welkomTekst}</h1>
            {/* <h2>Bezoekers voor bijeenkomst energiecoöperatie graag rechtsaf slaan en gang volgen tot aan de kantine</h2> */}
            {
              afspraken.length > 0 ?
              <div className="afspraken">
                {
                  afspraken.map((item, index) =>  {
                    return (
                      <Afspraak 
                      key={index} 
                      afspraak_met={item.afspraak_met} 
                      tijd_afspraak={item.tijd_afspraak}
                      logo={item.logo}
                      />
                    )
                  })
                }
              </div>
              :
              null
            }
          </div>
          <div className="footer">
            <div className="footer-content">
              <img src={icon} alt="icon" />
              <h4>{infoTekst}</h4>
              <ArrowDown className="arrow" />
            </div>
          </div>
          <img src={artwork} alt="Artwork" className="artwork" />
        </div>
      }
      <div className={'welcome-notification ' + (showNotification ? 'show' : '')}>
        <h2>{type === 'welcome' ? 'Welkom' : 'Tot ziens'} {naam}!</h2>
        {
          type === 'welcome' ?
          <p>Bedankt voor uw aanmelding. Neem rustig plaats, dan wordt u zo opgehaald.</p>
          :
          <p>U bent correct afgemeld</p>
        }
        
      </div>
  </div>
  );
}
export default App;
